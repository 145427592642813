import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_npxTable = _resolveComponent("npxTable")!

  return (_openBlock(), _createBlock(_component_npxTable, {
    pageTitle: "Program donášky",
    editPageRouter: "editprogram",
    insertButtonText: "Pridať program",
    firestoreCollection: "programs",
    defaultSortField: "title",
    documentFields: {
      title: {
        field: 'title',
        name: 'Názov programu',
        sortable: true,
        searchable: true,
        formatter: null,
      },
      price_per_day_3_meals: {
        field: 'price_per_day_3_meals',
        name: 'Cena za deň / 3 jedlá',
        sortable: true,
        searchable: true,
        formatter: null,
      },
      price_per_day_5_meals: {
        field: 'price_per_day_5_meals',
        name: 'Cena za deň / 5 jedál',
        sortable: true,
        searchable: true,
        formatter: null,
      },
      price_fee_gf: {
        field: 'price_fee_gf',
        name: 'Príplatok za bezlepkovú verziu',
        sortable: true,
        searchable: true,
        formatter: null,
      },
    }
  }))
}