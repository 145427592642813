import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import sk from "element-plus/es/locale/lang/sk";
import i18n from "@/core/plugins/i18n";
import firebase from "firebase/app";
import firebaseAuth from "firebase";
import "firebase/firestore";

firebase.initializeApp({
  apiKey: "AIzaSyDm3RVkrfySb4HX6A_ynGHUkU2PoaVBjLo",
  authDomain: "macingovadonaska.firebaseapp.com",
  projectId: "macingovadonaska",
  storageBucket: "macingovadonaska.appspot.com",
  messagingSenderId: "678589902148",
  appId: "1:678589902148:web:2bf9bb67ab663697937b11",
  measurementId: "G-BMQB5WDEMH",
});

firebaseAuth.auth().onAuthStateChanged(function (user) {
  if (!user) {
    router.push({ name: "sign-in" });
  }
});

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/keenthemes";
import "@/core/plugins/prismjs";
import "bootstrap";

const app = createApp(App);
export const languages = {
  sk: {
    title: "Slovenčina",
    shortcut: "sk",
    currency: "eur",
  },
  cz: {
    title: "Čeština",
    shortcut: "cz",
    currency: "czk",
  },
};

app.use(store);
app.use(router);
app.use(ElementPlus, {
  locale: sk,
});

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");
