const DocMenuConfig = [
  {
    pages: [
      {
        heading: "Nástenka",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "Modul: Objednávky",
    route: "/orders",
    pages: [
      {
        heading: "Objednávky",
        route: "/orders",
        svgIcon: "media/icons/duotune/general/gen014.svg",
        fontIcon: "bi-calendar3-event",
      },
      {
        sectionTitle: "Nastavenia",
        route: "/settings",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        sub: [
          {
            heading: "Program donášky",
            route: "/orders/settings/programs",
          },
          {
            heading: "Stav objednávky",
            route: "/orders/settings/statuses",
          },
          {
            heading: "Cyklus donášky",
            route: "/orders/settings/cycles",
          },
        ],
      },
    ],
  },
  {
    heading: "Modul: Skladový systém",
    route: "/warehouse",
    pages: [
      {
        heading: "Ukážková stránka",
        route: "/warehouse",
        svgIcon: "media/icons/duotune/general/gen014.svg",
        fontIcon: "bi-calendar3-event",
      },
    ],
  },
  {
    heading: "Modul: Etikety",
    route: "/labels",
    pages: [
      {
        heading: "Ukážková stránka",
        route: "/labels",
        svgIcon: "media/icons/duotune/general/gen014.svg",
        fontIcon: "bi-calendar3-event",
      },
    ],
  },
];

export default DocMenuConfig;
