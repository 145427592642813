
import { defineComponent } from "vue";
import { ElNotification } from "element-plus";
import { useRouter } from "vue-router";
import { languages } from "@/main";
import firebase from "firebase";

const router = useRouter();
const db = firebase.firestore();

export default defineComponent({
  name: "npxTable",
  components: {
    // CountryFlag
  },
  props: {
    pageTitle: {
      type: String,
      required: true,
    },
    insertButtonText: {
      type: String,
      required: true,
    },
    editPageRouter: {
      type: String,
      required: true,
    },
    firestoreCollection: {
      type: String,
      required: true,
    },
    documentFields: {
      type: Object,
      required: true,
    },
    defaultSortField: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tableData: [] as Array<any>,
      tableSearch: "",
      programs: [] as any,
      statuses: [] as any,
      languages: [] as any,
    };
  },
  created() {
    db.collection(this.firestoreCollection)
      .where("deleted", "not-in", [true, ""])
      .onSnapshot((snapshotChange) => {
        this.tableData = [];
        snapshotChange.forEach((doc) => {
          let tempData = [] as Array<unknown>;
          tempData["key"] = doc.id;
          for (let key in doc.data()) {
            tempData[key] = "";
            if (doc.data().multilanguage) {
              for (var language in languages) {
                //tempData[key] += doc.data()[key][language];
                tempData[key] = doc.data()[key].sk;
              }
            } else {
              tempData[key] = doc.data()[key];
            }
          }
          this.tableData.push(tempData);
        });
      });
    db.collection("programs")
      .where("deleted", "not-in", [true, ""])
      .onSnapshot((snapshotChange) => {
        snapshotChange.forEach((doc) => {
          this.programs[doc.id] = doc.data().title.sk;
        });
      });
    db.collection("statuses").onSnapshot((snapshotChange) => {
      snapshotChange.forEach((doc) => {
        this.statuses[doc.id] = doc.data();
      });
    });
    for (let key in languages) {
      this.languages.push(languages[key]);
    }
  },
  methods: {
    insertRecord() {
      this.$router.push(this.editPageRouter);
    },
    editRecord(row) {
      this.$router.push({
        name: this.editPageRouter,
        params: { key: row.key },
      });
    },
    confirmDelete(row) {
      db.collection(this.firestoreCollection)
        .doc(row.key)
        .update({ deleted: true })
        .then(() => {
          ElNotification({
            title: "Vymazané",
            message: "Položka bola úspešne vymazaná",
            type: "success",
          });
        })
        .catch(() => {
          ElNotification({
            title: "Chyba",
            message: "Nepodarilo sa vymazať položku",
            type: "error",
          });
        });
    },
    leadingZero(number: number) {
      return ("0" + number).slice(-2);
    },
    booleanFormatter(row, column, cellValue) {
      return cellValue ? "áno" : "nie";
    },
    dateFormatter(row, column, cellValue) {
      let myDate = new Date(cellValue.seconds * 1000);
      var day = myDate.getDate();
      var month = myDate.getMonth() + 1;
      var year = myDate.getFullYear();
      return this.leadingZero(day) + "." + this.leadingZero(month) + "." + year;
    },
    datetimeFormatter(row, column, cellValue) {
      let myDate = new Date(cellValue.seconds * 1000);
      var day = myDate.getDate();
      var month = myDate.getMonth() + 1;
      var year = myDate.getFullYear();
      var hours = myDate.getHours();
      var minutes = myDate.getMinutes();
      return this.leadingZero(day) + "." + this.leadingZero(month) + "." + year + " " + this.leadingZero(hours) + ":" + this.leadingZero(minutes);
    },
    programNameFormatter(row, column, cellValue) {
      return this.programs[cellValue];
    },
    statusNameFormatter(row, column, cellValue) {
      let status = this.statuses[cellValue];
      if (status) return status.title + " -> naformátovať";
    },
    addressFormatter(row, column, cellValue) {
      let result = "";
      if (column.property == "shipping_address") result = cellValue.first_name + ", " + cellValue.last_name + ", " + cellValue.street + ", " + cellValue.city + ", " + cellValue.country;
      if (column.property == "billing_address") result = cellValue.company + ", " + cellValue.street + ", " + cellValue.city + ", " + cellValue.country;
      return result;
    },
  },
});
