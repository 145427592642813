import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_npxTable = _resolveComponent("npxTable")!

  return (_openBlock(), _createBlock(_component_npxTable, {
    pageTitle: "Objednávky",
    editPageRouter: "editorder",
    insertButtonText: "Pridať objednávku",
    firestoreCollection: "orders",
    defaultSortField: "order_id",
    documentFields: {
      product_id: {
        field: 'product.product_id',
        name: 'Program',
        sortable: true,
        searchable: true,
        formatter: 'programName',
      },
      status: {
        field: 'status',
        name: 'Stav',
        sortable: true,
        searchable: true,
        formatter: 'statusName',
      },
      shipping_address: {
        field: 'shipping_address',
        name: 'Dodacia adresa',
        sortable: false,
        searchable: true,
        formatter: 'address',
      },
      billing_address: {
        field: 'billing_address',
        name: 'Fakturačná adresa',
        sortable: false,
        searchable: true,
        formatter: 'address',
      },
    }
  }, null, 8, ["documentFields"]))
}