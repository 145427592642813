import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_npxTable = _resolveComponent("npxTable")!

  return (_openBlock(), _createBlock(_component_npxTable, {
    pageTitle: "Status objednávky",
    editPageRouter: "editstatus",
    insertButtonText: "Pridať stav",
    firestoreCollection: "statuses",
    defaultSortField: "title",
    documentFields: {
      title: {
        field: 'title',
        name: 'Názov',
        sortable: true,
        searchable: true,
        formatter: null,
      },
      sendEmail: {
        field: 'sendEmail',
        name: 'Odoslať email',
        sortable: true,
        searchable: false,
        formatter: 'boolean',
      },
      sendInvoice: {
        field: 'sendInvoice',
        name: 'Odoslať faktúru',
        sortable: true,
        searchable: false,
        formatter: 'boolean',
      },
      emailSubject: {
        field: 'emailSubject',
        name: 'Predmet emailu',
        sortable: true,
        searchable: true,
        formatter: null,
      },
    }
  }))
}