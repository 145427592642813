import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_npxTable = _resolveComponent("npxTable")!

  return (_openBlock(), _createBlock(_component_npxTable, {
    pageTitle: "Cyklus objednávky",
    editPageRouter: "editcycle",
    insertButtonText: "Pridať cyklus",
    firestoreCollection: "cycles",
    defaultSortField: "cycleStartDate",
    documentFields: {
      cycleStartDate: {
        field: 'cycleStartDate',
        name: 'Začiatok cyklu',
        sortable: true,
        searchable: true,
        formatter: 'date',
      },
      cycleEndDate: {
        field: 'cycleEndDate',
        name: 'Koniec cyklu',
        sortable: true,
        searchable: true,
        formatter: 'date',
      },
      cycleShowDate: {
        field: 'cycleShowDate',
        name: 'Zobraziť cyklus od',
        sortable: true,
        searchable: true,
        formatter: 'datetime',
      },
      cycleHideDate: {
        field: 'cycleHideDate',
        name: 'Zobraziť cyklus do',
        sortable: true,
        searchable: true,
        formatter: 'datetime',
      },
    }
  }))
}