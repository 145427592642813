
import { defineComponent, ref } from "vue";
import CountryFlag from "vue-country-flag-next";
import npxForm from "@/components/neopixel/npxForm.vue";
import { languages } from "@/main";
import firebase from "firebase";

const db = firebase.firestore();

export default defineComponent({
  name: "statuses-list",
  components: {
    npxForm,
    CountryFlag,
  },
  data() {
    return {
      programs: [] as any,
      languages: [] as any,
    };
  },
  setup() {
    const localFieldValues = ref({
      order_id: "",
      email: "",
      phone: "",
      status: "",
      currency: "eur",
      product: {
        product_id: "",
        start_date: "",
        end_date: "",
        price_per_day: "",
        food_per_day: "",
        include_weekends: true,
        quantity: 1,
      },
      shipping_address: {
        first_name: "",
        last_name: "",
        street: "",
        city: "",
        region: "",
        country: "",
      },
      billing_address_differs: false,
      billing_address: {
        company: "",
        ico: "",
        dic: "",
        icdph: "",
        street: "",
        city: "",
        country: "",
      },
      note: "",
      internal_note: "",
      discount: 0,
      deleted: 0,
    });

    const localFieldRules = ref({});

    return {
      localFieldValues,
      localFieldRules,
    };
  },
  created() {
    db.collection("programs")
      .where("deleted", "not-in", [true, ""])
      .onSnapshot((snapshotChange) => {
        snapshotChange.forEach((doc) => {
          this.programs.push({ value: doc.id, label: doc.data().title.sk });
        });
      });
    for (let key in languages) {
      this.languages.push(languages[key]);
    }
  },
});
