
import { defineComponent, ref } from "vue";
import npxForm from "@/components/neopixel/npxForm.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/sk";

export default defineComponent({
  name: "statuses-list",
  components: {
    npxForm,
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: ["heading", "bold", "italic", "link", "bulletedList", "numberedList", "|", "outdent", "indent", "|", "undo", "redo"],
        },
        language: "sk",
      },
    };
  },
  setup() {
    const localFieldValues = ref({
      title: "",
      bgColor: "",
      fontColor: "",
      sendEmail: false,
      sendInvoice: false,
      emailSubject: "",
      emailBody: "",
      deleted: false,
    });

    const localFieldRules = ref({
      title: [
        {
          required: true,
          message: "Vložte prosím názov",
          trigger: "change",
        },
      ],
      bgColor: [
        {
          required: true,
          message: "Vyberte prosím farbu",
          trigger: "change",
        },
      ],
      fontColor: [
        {
          required: true,
          message: "Vyberte prosím farbu",
          trigger: "change",
        },
      ],
      emailSubject: [
        {
          required: true,
          message: "Vložte prosím predmet emailu",
          trigger: "change",
        },
      ],
      emailBody: [
        {
          required: true,
          message: "Vložte prosím obsah emailu",
          trigger: "change",
        },
      ],
    });

    return {
      localFieldValues,
      localFieldRules,
    };
  },
});
