import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/orders",
        name: "orders",
        component: () => import("@/views/orders/Orders.vue"),
      },
      {
        path: "/editorder",
        name: "editorder",
        component: () => import("@/views/orders/EditOrder.vue"),
      },
      {
        path: "/users",
        name: "users",
        component: () => import("@/views/orders/Users.vue"),
      },
      {
        path: "/orders/settings/statuses",
        name: "statuses",
        component: () => import("@/views/orders/settings/Statuses.vue"),
      },
      {
        path: "/orders/settings/editstatus",
        name: "editstatus",
        component: () => import("@/views/orders/settings/EditStatus.vue"),
      },
      {
        path: "/orders/settings/cycles",
        name: "cycles",
        component: () => import("@/views/orders/settings/Cycles.vue"),
      },
      {
        path: "/orders/settings/editcycle",
        name: "editcycle",
        component: () => import("@/views/orders/settings/EditCycle.vue"),
      },
      {
        path: "/orders/settings/programs",
        name: "programs",
        component: () => import("@/views/orders/settings/Programs.vue"),
      },
      {
        path: "/orders/settings/editprogram",
        name: "editprogram",
        component: () => import("@/views/orders/settings/EditProgram.vue"),
      },
      {
        path: "/warehouse",
        name: "warehouse",
        component: () => import("@/views/warehouse/Warehouse.vue"),
      },
      {
        path: "/labels",
        name: "labels",
        component: () => import("@/views/labels/Labels.vue"),
      },
      {
        path: "/changelog",
        name: "changelog",
        component: () => import("@/views/Changelog.vue"),
      },
      {
        path: "/support",
        name: "support",
        component: () => import("@/views/Support.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () => import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () => import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  //store.dispatch(Actions.VERIFY_AUTH);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
