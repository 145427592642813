
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import firebase from "firebase";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLButtonElement | null>(null);

    //Form submit function
    const onSubmitLogin = (values) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(values.email, values.password)
        .then((userCredential) => {
          if (submitButton.value) {
            submitButton.value.disabled = true;
            submitButton.value.setAttribute("data-kt-indicator", "on");
            router.push({ name: "dashboard" });
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            text: "Neúspešne prihlásenie. Skontrolujte prosím zadané údaje.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Skúste to znova!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      /*
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Dummy delay
      setTimeout(() => {
        // Send login request
        store
          .dispatch(Actions.LOGIN, values)
          .then(() => {
            Swal.fire({
              text: "Prihlásenie prebehlo úspešne!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, rozumiem",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              // Go to page after successfully login
              router.push({ name: "dashboard" });
            });
          })
          .catch(() => {
            const [error] = Object.keys(store.getters.getErrors);
            Swal.fire({
              text: store.getters.getErrors[error],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Skúste to znova!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        //Deactivate indicator
        submitButton.value?.removeAttribute("data-kt-indicator");
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
      }, 2000);
      */
    };

    return {
      onSubmitLogin,
      submitButton,
    };
  },
});
