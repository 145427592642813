
import { defineComponent } from "vue";
import npxTable from "@/components/neopixel/npxTable.vue";

export default defineComponent({
  name: "orders-list",
  components: {
    npxTable,
  },
});
