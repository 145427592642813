
import { defineComponent, ref } from "vue";
import { languages } from "@/main";
import npxForm from "@/components/neopixel/npxForm.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/sk";

export default defineComponent({
  name: "cycles-list",
  components: {
    npxForm,
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      languages: languages,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: ["heading", "bold", "italic", "link", "bulletedList", "numberedList", "|", "outdent", "indent", "|", "undo", "redo"],
        },
        language: "sk",
      },
    };
  },
  setup() {
    const localFieldValues = ref({
      title: setLangDefaultValue(""),
      price_per_day_3_meals: setLangDefaultValue(0),
      price_per_day_5_meals: setLangDefaultValue(0),
      price_fee_gf: setLangDefaultValue(0),
      duration_in_days: setLangDefaultValue(0),
      duration_by_customer: setLangDefaultValue(0),
      start_by_customer: setLangDefaultValue(0),
      description: setLangDefaultValue(""),
      multilanguage: true,
      deleted: false,
    });

    const localFieldRules = ref({
      title: [
        {
          required: true,
          message: "Vložte prosím názov",
          trigger: "change",
        },
      ],
      price_per_day_3_meals: [
        {
          required: true,
          message: "Vložte prosím cenu",
          trigger: "change",
        },
      ],
      price_per_day_5_meals: [
        {
          required: true,
          message: "Vložte prosím cenu",
          trigger: "change",
        },
      ],
      price_fee_gf: [
        {
          required: true,
          message: "Vložte prosím cenu",
          trigger: "change",
        },
      ],
      description: [
        {
          required: true,
          message: "Vložte prosím popis programu",
          trigger: "change",
        },
      ],
    });

    function setLangDefaultValue(value) {
      let result = {};
      for (var key in languages) {
        result[languages[key].shortcut] = value;
      }
      return result;
    }

    return {
      localFieldValues,
      localFieldRules,
    };
  },
});
