
import { defineComponent, ref } from "vue";
import npxForm from "@/components/neopixel/npxForm.vue";

export default defineComponent({
  name: "cycles-list",
  components: {
    npxForm,
  },
  setup() {
    const localFieldValues = ref({
      cycleStartDate: "",
      cycleEndDate: "",
      cycleShowDate: "",
      cycleHideDate: "",
      deleted: false,
    });

    const localFieldRules = ref({
      cycleStartDate: [
        {
          required: true,
          message: "Vyberte prosím dátum",
          trigger: "change",
        },
      ],
      cycleEndDate: [
        {
          required: true,
          message: "Vyberte prosím dátum",
          trigger: "change",
        },
      ],
      cycleShowDate: [
        {
          required: true,
          message: "Vyberte prosím dátum",
          trigger: "change",
        },
      ],
      cycleHideDate: [
        {
          required: true,
          message: "Vyberte prosím dátum",
          trigger: "change",
        },
      ],
    });

    return {
      localFieldValues,
      localFieldRules,
    };
  },
});
