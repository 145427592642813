
import { defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { languages } from "@/main";
import CountryFlag from "vue-country-flag-next";
import firebase from "firebase";
import Swal from "sweetalert2/dist/sweetalert2.js";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/sk";

const db = firebase.firestore();

export default defineComponent({
  name: "npxForm",
  components: {
    CountryFlag,
    // eslint-disable-next-line vue/no-unused-components
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      languages: languages,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: ["heading", "bold", "italic", "link", "bulletedList", "numberedList", "|", "outdent", "indent", "|", "undo", "redo"],
        },
        language: "sk",
      },
    };
  },
  props: {
    cardWrapper: {
      type: Boolean,
      required: false,
    },
    multilanguage: {
      type: Boolean,
      required: false,
    },
    pageTitleNew: {
      type: String,
      required: true,
    },
    pageSubtitleNew: {
      type: String,
      required: true,
    },
    pageTitleEdit: {
      type: String,
      required: true,
    },
    pageSubtitleEdit: {
      type: String,
      required: true,
    },
    listPageRouter: {
      type: String,
      required: true,
    },
    firestoreCollection: {
      type: String,
      required: true,
    },
    fieldTypes: {
      type: Object,
      required: true,
    },
    fieldValues: {
      type: Object,
      required: true,
    },
    fieldRules: {
      type: Object,
      required: true,
    },
  },
  created() {
    const route = useRoute();
    if (route.params.key) {
      db.collection(this.firestoreCollection)
        .doc(String(route.params.key))
        .get()
        .then((doc) => {
          if (doc.exists) {
            for (let key in doc.data()) {
              let docData = doc.data() || "";
              this.localFieldValues[key] = docData[key];
            }
          } else {
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    }
  },
  setup(props) {
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const router = useRouter();
    const route = useRoute();

    const localFieldValues = ref(props.fieldValues);
    const localFieldRules = ref(props.fieldRules);

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;

          if (route.params.key) {
            db.collection(props.firestoreCollection)
              .doc(String(route.params.key))
              .update(localFieldValues.value)
              .then((docRef) => messageDocumentSaved(docRef))
              .catch((error) => messageDocumentError(error));
          } else {
            db.collection(props.firestoreCollection)
              .add(localFieldValues.value)
              .then((docRef) => messageDocumentSaved(docRef))
              .catch((error) => messageDocumentError(error));
          }
        } else {
          Swal.fire({
            text: "Ľutujeme, ale boli zistené chyby. Opravte ich a skúste to znova.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "OK, rozumiem",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    const messageDocumentSaved = (docRef) => {
      Swal.fire({
        text: "Dokument bol úspešne uložený!",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "OK, rozumiem",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      }).then(() => {
        router.push({ name: props.listPageRouter });
      });
    };

    const messageDocumentError = (error) => {
      Swal.fire({
        text: "Nastal problém pri ukladaní do databázy. Kontaktujte prosím administrátora. Popis chyby: " + error,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "OK, rozumiem",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    };

    return {
      localFieldValues,
      submit,
      loading,
      formRef,
      localFieldRules,
      messageDocumentSaved,
      messageDocumentError,
    };
  },
});
